import IndexWebglSlider from './index/webgl-slider/slider';
import IndexTab from './index/index-tab';
import IndexTopicsView from './index/index-topics-view';
import IndexTopicsStickyArea from './index/index-topics-sticky-area';
import IndexNewsSlider from './index/indx-news-slider';
import * as THREE from 'three';

export default class Index{
  constructor(){
    window.THREE = THREE;

    new IndexWebglSlider();
    new IndexTab();
    new IndexNewsSlider();
    new IndexTopicsView();
    new IndexTopicsStickyArea();
  }
}

new Index();