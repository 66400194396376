export default class GlElements {
  constructor(targetName) {
    this.targetName = targetName;
    this.optionList = {};
  }

  init() {
    this._initOptionList();
  }

  _initOptionList() {
    const $target = document.querySelector(this.targetName);

    this.optionList.$target = $target;
    this.optionList.width = $target.getBoundingClientRect().width;
    this.optionList.height = $target.getBoundingClientRect().height;

    const canvas = document.createElement('canvas');

    let imageExt = (canvas.toDataURL('image/webp').indexOf('data:image/webp') == 0) ? '.webp' : '';
    let noiseExt = (canvas.toDataURL('image/webp').indexOf('data:image/webp') == 0) ? '.webp' : '.jpg';
    //ローカルサーバーの場合はwebpにしない
    if(window.location.hostname === 'localhost'){
      imageExt = '';
      noiseExt = '.jpg';
    }

    this.optionList.img01_src = $target.dataset.image01 + imageExt;
    this.optionList.img02_src = $target.dataset.image02 + imageExt;
    this.optionList.img03_src = $target.dataset.image03 + imageExt;
    // this.optionList.img04_src = $target.dataset.image04 + imageExt;
    this.optionList.noise_src = document.querySelector('body').dataset.noise + noiseExt;
  }

  _setOptionList() {
    this.optionList.width = this.optionList.$target.getBoundingClientRect().width;
    this.optionList.height = this.optionList.$target.getBoundingClientRect().height;
  }

  onResize() {
    this._setOptionList();
  }
}