export default class IndexNewsSlider {
  constructor() {
    this._getParam();
    this.init();
    this._autoChangeSlide();

    this.nextBtn.addEventListener("click", () => {
      this.autochangeslide.restart();
      this._moveChangeSlide("prev");
    });

    this.prevBtn.addEventListener("click", () => {
      this.autochangeslide.restart();
      this._moveChangeSlide("next");
    });
  }

  init() {
    this._elementInit(this.slide);
    this._elementInit(this.linkBtn);
  }
  /**
   * @description パラメーターの取得・設定
   */
  _getParam() {
    this.slide = document.querySelectorAll(".index-mv__news__link");
    this.index = document.querySelectorAll(".index-mv__news__nav__pagenav__num");
    this.prevBtn = document.querySelector(".index-mv__news__arrow--prev");
    this.nextBtn = document.querySelector(".index-mv__news__arrow--next");
    this.currentNum = 0;
    this.oldCurrentNum = this.slide.length - 1.0;
    this.autoDuration = 6.0;
    this.duration = 0.6;
    this.ease = "power2.inOut";
    this.animationFlag = false;
    this.linkBtn = document.querySelectorAll(".index-mv__news__link--circle");
  }

  /**
   * @description 左右にスライドを変える
   */
  _moveChangeSlide(direction) {
    if (this.animationFlag) return;

    this.disable();

    this.oldCurrentNum = this.currentNum;

    switch (direction) {
      case "prev":
        if (this.currentNum >= this.slide.length - 1.0) {
          this.currentNum = 0;
        } else {
          this.currentNum += 1.0;
        }
        this._elementInit(this.slide);
        this._elementInit(this.linkBtn);
        this._slideAnimation(this.slide, direction);
        this._slideAnimation(this.index, direction);
        break;
      case "next":
        if (this.currentNum < 1.0) {
          this.currentNum = this.slide.length - 1.0;
        } else {
          this.currentNum += -1.0;
        }
        this._elementInit(this.slide);
        this._elementInit(this.linkBtn);
        this._slideAnimation(this.slide, direction);
        this._slideAnimation(this.index, direction);
        break;
    }

  }

  /**
   * @description 6秒ごとに右に向けてスライドを切り替える。
   */
  _autoChangeSlide() {
    this.autochangeslide = window.gsap
      .to(
        {},
        {
          ease: "none",
          duration: this.autoDuration,
          repeat: -1.0,
        }
      )
      .eventCallback("onRepeat", () => {
        this._moveChangeSlide("prev");
      });
  }
  /**
   * @description クラスの振り直し
   */
  _elementInit(element) {
    for (let i = 0; i < element.length; i++) {
      element[i].classList.remove("is-active");
    }
    element[this.currentNum].classList.add("is-active");

    for (let i = 0; i < element.length; i++) {
      element[i].classList.remove("is-old-active");
    }
    element[this.oldCurrentNum].classList.add("is-old-active");
  }
  /**
   * @description スライドアニメーション
   */
  _slideAnimation(element, direction) {

    switch (direction) {
      case "prev":
        this.direction = -1.0;
        break;
      case "next":
        this.direction = 1.0;
        break;
    }

    gsap.fromTo(
      element[this.oldCurrentNum],
      {
        opacity: 1,
        xPercent: "0",
      },
      {
        duration: this.duration,
        ease: this.ease,
        opacity: 0,
        xPercent: 7 * this.direction,
      }
    );

    gsap.fromTo(
      element[this.currentNum],
      {
        opacity: 0,
        xPercent: -7 * this.direction,
      },
      {
        duration: this.duration,
        delay: this.duration - this.duration / 1.0,
        ease: this.ease,
        opacity: 1,
        xPercent: "0",
      }
    );
  }

  /**
   * @description 操作不能
   */
  disable() {
    this.animationFlag = true;
    setTimeout(() => {
      this.animationFlag = false;
    }, this.duration * 1000 * 2.0);
  }
}
