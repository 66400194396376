import vertexShader from './shaders/vertexshader.vert';
import fragmentShader from './shaders/fragmentshader.frag';

export default class Mesh {
  constructor(stage, glElements) {
    // スクリーンサイズに下記のサイズを乗算したサイズがスクリーンに描画されるMeshのサイズとなる
    this.meshWindowSizeRatio = { x: 1.0, y: 1.0 };

    // ジオメトリー生成用のパラメータ
    this.geometryParm = {
      width: 1.0, // ジオメトリ生成後、リサイズによるmeshのサイズ変更を見越して、1で固定、サイズの変更はmeshのscaleプロパティを変更して行う
      height: 1.0, // ジオメトリ生成後、リサイズによるmeshのサイズ変更を見越して、1で固定、サイズの変更はmeshのscaleプロパティを変更して行う
      widthSegments: 1.0, // 板ポリゴン内のセルの数（X軸）
      heightSegments: 1.0 // 板ポリゴン内のセルの数（Y軸）
    };

    this.materialParam = {
      useWireframe: false
    };

    this.glElements = glElements;

    const texture1 = new THREE.TextureLoader().load(this.glElements.optionList.img01_src, () => {
      this.uniforms.u_texturesize_01.value.x = texture1.image.naturalWidth;
      this.uniforms.u_texturesize_01.value.y = texture1.image.naturalHeight;
    });

    const texture2 = new THREE.TextureLoader().load(this.glElements.optionList.img02_src, () => {
      this.uniforms.u_texturesize_02.value.x = texture2.image.naturalWidth;
      this.uniforms.u_texturesize_02.value.y = texture2.image.naturalHeight;
    });

    const texture3 = new THREE.TextureLoader().load(this.glElements.optionList.img03_src, () => {
      this.uniforms.u_texturesize_03.value.x = texture3.image.naturalWidth;
      this.uniforms.u_texturesize_03.value.y = texture3.image.naturalHeight;
    });

    // const texture4 = new THREE.TextureLoader().load(this.glElements.optionList.img04_src, () => {
    //   this.uniforms.u_texturesize_04.value.x = texture4.image.naturalWidth;
    //   this.uniforms.u_texturesize_04.value.y = texture4.image.naturalHeight;
    // });

    // マテリアル（シェーダの中）で使用するユニフォーム変数
    this.uniforms = {
      u_noise_texture: {
        type: "t",
        value: new THREE.TextureLoader().load(this.glElements.optionList.noise_src)
      },
      u_texture_01: {
        type: "t",
        value: texture1
      },
      u_texture_02: {
        type: "t",
        value: texture2
      },
      u_texture_03: {
        type: "t",
        value: texture3
      },
      // u_texture_04: {
      //   type: "t",
      //   value: texture4
      // },
      u_noise_switch_01: {
        type: "f",
        value: 0.0,
      },
      u_noise_switch_02: {
        type: "f",
        value: 0.0,
      },
      u_noise_switch_03: {
        type: "f",
        value: 0.0,
      },
      // u_noise_switch_04: {
      //   type: "f",
      //   value: 0.0,
      // },
      u_texture_switch_01: {
        type: "f",
        value: 1.0,
      },
      u_texture_switch_02: {
        type: "f",
        value: 0.0,
      },
      u_texture_switch_03: {
        type: "f",
        value: 0.0,
      },
      // u_texture_switch_04: {
      //   type: "f",
      //   value: 0.0,
      // },
      u_noise_power: {
        type: "f",
        value: 0.4,
      },
      u_angle: {
        type: "f",
        value: -1.0,
      },
      u_meshsize: {
        type: "v2",
        value: { x: this.glElements.optionList.width, y: this.glElements.optionList.height }
      },
      u_texturesize_01: {
        type: "v2",
        value: { x: 0, y: 0 }
      },
      u_texturesize_02: {
        type: "v2",
        value: { x: 0, y: 0 }
      },
      u_texturesize_03: {
        type: "v2",
        value: { x: 0, y: 0 }
      },
      // u_texturesize_04: {
      //   type: "v2",
      //   value: { x: 0, y: 0 }
      // }
    };

    this.stage = stage;

    this.mesh = null;

    this.duration = 0.9;
    this.ease = "power1.out";
  }

  init() {
    this._setMesh();
    this._setMeshScale();
  }

  _setMesh() {
    // ジオメトリーを生成
    const geometry = new THREE.PlaneBufferGeometry(
      this.geometryParm.width,
      this.geometryParm.height,
      this.geometryParm.widthSegments,
      this.geometryParm.heightSegments
    );

    // マテリアルを生成
    const material = new THREE.RawShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      wireframe: this.materialParam.useWireframe,
      transparent: true,
      uniforms: this.uniforms
    });

    this.mesh = new THREE.Mesh(geometry, material);
    this.stage.scene.add(this.mesh);
  }

  _setMeshScale() {
    this.mesh.scale.x = this.glElements.optionList.width;
    this.mesh.scale.y = this.glElements.optionList.height;

    this.uniforms.u_meshsize.value.x = this.mesh.scale.x;
    this.uniforms.u_meshsize.value.y = this.mesh.scale.y;
  }

  // _setGui() {
  //   const parameter = {
  //     duration: this.duration,
  //     noise: this.mesh.material.uniforms.u_noise_power.value,
  //     angle: this.mesh.material.uniforms.u_angle.value,
  //   };
  //   const sliderFolder = window.gui.addFolder('slider')
  //   sliderFolder
  //     .add(parameter, "duration", 0.0, 2.0, 0.01)
  //     .name("duration")
  //     .onChange((value) => {
  //       this.duration = value;
  //     });
  //   sliderFolder
  //     .add(parameter, "noise", -1.0, 1.0, 0.01)
  //     .name("noisepower")
  //     .onChange((value) => {
  //       this.mesh.material.uniforms.u_noise_power.value = value;
  //     });
  //   sliderFolder
  //     .add(parameter, "angle", -2.0, 2.0, 0.01)
  //     .name("angle")
  //     .onChange((value) => {
  //       this.mesh.material.uniforms.u_angle.value = value;
  //     });
  //   sliderFolder.close();
  // }

  onResize() {
    this._setMeshScale();
  }

  _setTargetNoise(number) {
    switch (number) {
      case 0:
        gsap.fromTo(this.mesh.material.uniforms.u_noise_switch_01,
          {
            value: -1.0
          },
          {
            duration: this.duration,
            ease: this.ease,
            value: 0.0,
          });
        gsap.to(this.mesh.material.uniforms.u_noise_switch_02, {
          duration: this.duration,
          ease: this.ease,
          value: 1.0,
        });
        gsap.to(this.mesh.material.uniforms.u_noise_switch_03, {
          duration: this.duration,
          ease: this.ease,
          value: 1.0,
        });
        // gsap.to(this.mesh.material.uniforms.u_noise_switch_04, {
        //   duration: this.duration,
        //   ease: this.ease,
        //   value: 1.0,
        // });
        break;
      case 1:
        gsap.fromTo(this.mesh.material.uniforms.u_noise_switch_02,
          {
            value: -1.0
          },
          {
            duration: this.duration,
            ease: this.ease,
            value: 0.0,
          });
        gsap.to(this.mesh.material.uniforms.u_noise_switch_01, {
          duration: this.duration,
          ease: this.ease,
          value: 1.0,
        });
        gsap.to(this.mesh.material.uniforms.u_noise_switch_03, {
          duration: this.duration,
          ease: this.ease,
          value: 1.0,
        });
        // gsap.to(this.mesh.material.uniforms.u_noise_switch_04, {
        //   duration: this.duration,
        //   ease: this.ease,
        //   value: 1.0,
        // });
        break;
      case 2:
        gsap.fromTo(this.mesh.material.uniforms.u_noise_switch_03,
          {
            value: -1.0
          },
          {
            duration: this.duration,
            ease: this.ease,
            value: 0.0,
          });
        gsap.to(this.mesh.material.uniforms.u_noise_switch_01, {
          duration: this.duration,
          ease: this.ease,
          value: 1.0,
        });
        gsap.to(this.mesh.material.uniforms.u_noise_switch_02, {
          duration: this.duration,
          ease: this.ease,
          value: 1.0,
        });
        // gsap.to(this.mesh.material.uniforms.u_noise_switch_04, {
        //   duration: this.duration,
        //   ease: this.ease,
        //   value: 1.0,
        // });
        break;
      // case 3:
      //   gsap.fromTo(this.mesh.material.uniforms.u_noise_switch_04,
      //     {
      //       value: -1.0
      //     },
      //     {
      //       duration: this.duration,
      //       ease: this.ease,
      //       value: 0.0,
      //     });
      //   gsap.to(this.mesh.material.uniforms.u_noise_switch_01, {
      //     duration: this.duration,
      //     ease: this.ease,
      //     value: 1.0,
      //   });
      //   gsap.to(this.mesh.material.uniforms.u_noise_switch_02, {
      //     duration: this.duration,
      //     ease: this.ease,
      //     value: 1.0,
      //   });
      //   gsap.to(this.mesh.material.uniforms.u_noise_switch_03, {
      //     duration: this.duration,
      //     ease: this.ease,
      //     value: 1.0,
      //   });
      //   break;
    }
  }

  _setNoise(number) {
    switch (number) {
      case 0:
        gsap.fromTo(this.mesh.material.uniforms.u_noise_switch_01,
          {
            value: 0.0,
          },
          {
            duration: this.duration,
            ease: this.ease,
            value: 1.0,
          });
        gsap.fromTo(this.mesh.material.uniforms.u_noise_switch_02,
          {
            value: -1.0,
          },
          {
            duration: this.duration,
            ease: this.ease,
            value: 0.0,
          });
        break;
      case 1:
        gsap.fromTo(this.mesh.material.uniforms.u_noise_switch_02,
          {
            value: 0.0,
          },
          {
            duration: this.duration,
            ease: this.ease,
            value: 1.0,
          });
        gsap.fromTo(this.mesh.material.uniforms.u_noise_switch_03,
          {
            value: -1.0,
          },
          {
            duration: this.duration,
            ease: this.ease,
            value: 0.0,
          });
        break;
      case 2:
        gsap.fromTo(this.mesh.material.uniforms.u_noise_switch_03,
          {
            value: 0.0,
          },
          {
            duration: this.duration,
            ease: this.ease,
            value: 1.0,
          });
        gsap.fromTo(this.mesh.material.uniforms.u_noise_switch_01,
          {
            value: -1.0,
          },
          {
            duration: this.duration,
            ease: this.ease,
            value: 0.0,
          });
        break;
      // case 3:
      //   gsap.fromTo(this.mesh.material.uniforms.u_noise_switch_04,
      //     {
      //       value: 0.0,
      //     },
      //     {
      //       duration: this.duration,
      //       ease: this.ease,
      //       value: 1.0,
      //     });
      //   gsap.fromTo(this.mesh.material.uniforms.u_noise_switch_01,
      //     {
      //       value: -1.0,
      //     },
      //     {
      //       duration: this.duration,
      //       ease: this.ease,
      //       value: 0.0,
      //     });
      //   break;
    }
  }

  _targetChangeSlide(number) {
    this._setTargetNoise(number);

    switch (number) {
      case 0:
        gsap.to(this.mesh.material.uniforms.u_texture_switch_01, {
          duration: this.duration,
          ease: this.ease,
          value: 1.0
        });
        gsap.to(this.mesh.material.uniforms.u_texture_switch_02, {
          duration: this.duration,
          ease: this.ease,
          value: 0.0
        });
        gsap.to(this.mesh.material.uniforms.u_texture_switch_03, {
          duration: this.duration,
          ease: this.ease,
          value: 0.0
        });
        // gsap.to(this.mesh.material.uniforms.u_texture_switch_04, {
        //   duration: this.duration,
        //   ease: this.ease,
        //   value: 0.0
        // });
        break;
      case 1:
        gsap.to(this.mesh.material.uniforms.u_texture_switch_01, {
          duration: this.duration,
          ease: this.ease,
          value: 0.0
        });
        gsap.to(this.mesh.material.uniforms.u_texture_switch_02, {
          duration: this.duration,
          ease: this.ease,
          value: 1.0
        });
        gsap.to(this.mesh.material.uniforms.u_texture_switch_03, {
          duration: this.duration,
          ease: this.ease,
          value: 0.0
        });
        // gsap.to(this.mesh.material.uniforms.u_texture_switch_04, {
        //   duration: this.duration,
        //   ease: this.ease,
        //   value: 0.0
        // });
        break;
      case 2:
        gsap.to(this.mesh.material.uniforms.u_texture_switch_01, {
          duration: this.duration,
          ease: this.ease,
          value: 0.0
        });
        gsap.to(this.mesh.material.uniforms.u_texture_switch_02, {
          duration: this.duration,
          ease: this.ease,
          value: 0.0
        });
        gsap.to(this.mesh.material.uniforms.u_texture_switch_03, {
          duration: this.duration,
          ease: this.ease,
          value: 1.0
        });
        // gsap.to(this.mesh.material.uniforms.u_texture_switch_04, {
        //   duration: this.duration,
        //   ease: this.ease,
        //   value: 0.0
        // });
        break;
      // case 3:
      //   gsap.to(this.mesh.material.uniforms.u_texture_switch_01, {
      //     duration: this.duration,
      //     ease: this.ease,
      //     value: 0.0
      //   });
      //   gsap.to(this.mesh.material.uniforms.u_texture_switch_02, {
      //     duration: this.duration,
      //     ease: this.ease,
      //     value: 0.0
      //   });
      //   gsap.to(this.mesh.material.uniforms.u_texture_switch_03, {
      //     duration: this.duration,
      //     ease: this.ease,
      //     value: 0.0
      //   });
      //   gsap.to(this.mesh.material.uniforms.u_texture_switch_04, {
      //     duration: this.duration,
      //     ease: this.ease,
      //     value: 1.0
      //   });
      //   break;
    }
  }

  _changeSlide(number) {
    this._setNoise(number);

    switch (number) {
      case 0:
        gsap.to(this.mesh.material.uniforms.u_texture_switch_01, {
          duration: this.duration,
          ease: this.ease,
          value: 0.0
        });
        gsap.to(this.mesh.material.uniforms.u_texture_switch_02, {
          duration: this.duration,
          ease: this.ease,
          value: 1.0
        });
        break;
      case 1:
        gsap.to(this.mesh.material.uniforms.u_texture_switch_02, {
          duration: this.duration,
          ease: this.ease,
          value: 0.0
        });
        gsap.to(this.mesh.material.uniforms.u_texture_switch_03, {
          duration: this.duration,
          ease: this.ease,
          value: 1.0
        });
        break;
      case 2:
        gsap.to(this.mesh.material.uniforms.u_texture_switch_03, {
          duration: this.duration,
          ease: this.ease,
          value: 0.0
        });
        gsap.to(this.mesh.material.uniforms.u_texture_switch_01, {
          duration: this.duration,
          ease: this.ease,
          value: 1.0
        });
        break;
      // case 3:
      //   gsap.to(this.mesh.material.uniforms.u_texture_switch_04, {
      //     duration: this.duration,
      //     ease: this.ease,
      //     value: 0.0
      //   });
      //   gsap.to(this.mesh.material.uniforms.u_texture_switch_01, {
      //     duration: this.duration,
      //     ease: this.ease,
      //     value: 1.0
      //   });
      //   break;
    }
  }

  _render() {
    //
  }

  onRaf() {
    this._render();
  }
}