export default class IndexTab {
  constructor() {
    const tabArea = document.querySelector('.index-topics__article-area');
    const tabCatList = document.querySelectorAll('.index-topics__side-nav__item-block');
    const tabContentList = document.querySelectorAll('.index-topics__article__list');

    const setHeight = () => {
      for (let i = 0; i < tabContentList.length; i++) {
        if (tabContentList[i].classList.contains('is-active')) {
          tabArea.style.height = `${tabContentList[i].clientHeight}px`;
        }
      }
    }
    setHeight();

    window.addEventListener('resize', () => {
      setHeight();
    });

    for (let i = 0; i < tabCatList.length; i++) {
      tabCatList[i].addEventListener('click', () => {
        //content
        for (let i = 0; i < tabContentList.length; i++) {
          if (tabContentList[i].classList.contains('is-active')) {
            gsap.to(tabContentList[i], {
              duration: 0.3,
              ease: 'power1.out',
              opacity: 0,
            });
          }
        }

        gsap.to(tabContentList[i], {
          duration: 0.3,
          delay: 0.3,
          ease: 'power1.out',
          opacity: 1,
        });

        //btn
        for (let i = 0; i < tabCatList.length; i++) {
          tabCatList[i].classList.remove('is-active');
          tabContentList[i].classList.remove('is-active');
        }
        tabCatList[i].classList.add('is-active');
        tabContentList[i].classList.add('is-active');

        setHeight();
      });
    }
  }
}