export default class IndexTopicsStickyArea {
  constructor() {
    this.heightArea = document.querySelector('.index-topics__side-nav');
    this.stickyTargetHeightArea = document.querySelectorAll('.index-topics__article__list');
    this.activeStickyTargetHeightArea = null;
    this.deviceFlag = window.innerWidth > BREAKPOINT ? true : false;

    this.setHeight();

    window.addEventListener('resize', () => {
      this.setHeight();
    });

    const tabCatList = document.querySelectorAll('.index-topics__side-nav__item-block');
    for (let i = 0; i < tabCatList.length; i++) {
      tabCatList[i].addEventListener('click', () => {
        this.setHeight();
      });
    }
  }

  setHeight() {
    this.deviceFlag = window.innerWidth > BREAKPOINT ? true : false;

    if (!this.deviceFlag) {
      this.heightArea.removeAttribute('style');
      return;
    }

    for (let i = 0; i < this.stickyTargetHeightArea.length; i++) {
      if (this.stickyTargetHeightArea[i].classList.contains('is-active')) {
        this.activeStickyTargetHeightArea = this.stickyTargetHeightArea[i];
      }
    }
    this.heightArea.style.height = `${this.activeStickyTargetHeightArea.clientHeight}px`;
  }
}