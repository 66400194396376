export default class IndexTopicsView {
  constructor() {
    const observe = document.querySelector('.index-topics');
    const target = document.querySelector('body');
    const topicsBtn = document.querySelector('.index-topics .cmn-btn');

    new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          target.classList.add('is-index-topics-view');

          topicsBtn.classList.add('cmn-btn--white');
          topicsBtn.classList.remove('cmn-btn--gray');
        } else {
          target.classList.remove('is-index-topics-view');

          topicsBtn.classList.remove('cmn-btn--white');
          topicsBtn.classList.add('cmn-btn--gray');
        }
      });
    }, {
      rootMargin: '-40%',
    }).observe(observe);
  }
}