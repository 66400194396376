import Element from './elements';
import Mesh from './mesh';
import Stage from '../../common/webgl-common-parts/stage';

export default class Slider {
  constructor() {
    const observe = document.querySelector('.js-webgl-slider');
    let animationFlag = false;

    new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          animationFlag = true
          if (this.sliderAnimation) this.sliderAnimation.play();
          if (this.currentCircleAnimation) this.currentCircleAnimation.play();
          if (this.oldCurrentCircleAnimation) this.oldCurrentCircleAnimation.play();
        } else {
          animationFlag = false;
          if (this.sliderAnimation) this.sliderAnimation.pause();
          if (this.currentCircleAnimation) this.currentCircleAnimation.pause();
          if (this.oldCurrentCircleAnimation) this.oldCurrentCircleAnimation.pause();
        }
      });
    }, {
      rootMargin: '0%',
    }).observe(observe);

    const glElements = new Element(".js-webgl-slider");
    glElements.init();

    const stage = new Stage();
    stage.init();

    const mesh = new Mesh(stage, glElements);
    mesh.init();

    window.addEventListener("resize", () => {
      glElements.onResize();
      stage.onResize();
      mesh.onResize();
    });

    const _raf = () => {
      window.requestAnimationFrame(() => {
        _raf();

        if (!animationFlag) return;
        stage.onRaf();
        mesh.onRaf();
      });
    };

    _raf();

    const bulletBtn = document.querySelectorAll('.index-mv__bullet__nav__list');
    const bulletBtnCircle = document.querySelectorAll('.index-mv__bullet__nav__list circle');
    const bulletBtnCircleLength = 75;
    const bulletLastBtn = document.querySelector('.index-mv__bullet__nav__list__svg--03');
    this.sliderAnimation = null;
    this.currentNum = 0;
    this.oldCurrentNum = 2;
    this.allDuration = 6.0;

    const _circleAnimation = () => {
      this.currentCircleAnimation = gsap.fromTo(bulletBtnCircle[this.currentNum],
        {
          strokeDashoffset: bulletBtnCircleLength,
        },
        {
          ease: "none",
          duration: this.allDuration,
          strokeDashoffset: 0,
        }
      )
      this.oldCurrentCircleAnimation = gsap.to(bulletBtnCircle[this.oldCurrentNum],
        {
          ease: "none",
          duration: 0.4,
          strokeDashoffset: -bulletBtnCircleLength,
        }
      )
    }

    const _targetCircleAnimation = () => {
      this.currentCircleAnimation.pause();
      this.oldCurrentCircleAnimation.pause();

      _circleAnimation();
    }

    const _moveChangeSlide = () => {
      this.oldCurrentNum = this.currentNum;

      if (this.currentNum > 1) {
        this.currentNum = 0;
      } else {
        this.currentNum++;
      }
    }

    const _autoChangeSlide = () => {
      this.sliderAnimation = gsap.to(
        {},
        {
          ease: "none",
          duration: this.allDuration,
          repeat: -1.0
        }
      )
        .eventCallback("onRepeat", () => {
          // if (!animationFlag) return;
          mesh._changeSlide(this.currentNum);
          _moveChangeSlide();
          _circleAnimation();
        });
    }

    window.addEventListener("load", () => {
      _circleAnimation();
      _autoChangeSlide();
      setTimeout(() => {
        bulletLastBtn.style.opacity = 1.0;
      }, 400);
    });

    for (let i = 0; i < bulletBtn.length; i++) {
      bulletBtn[i].addEventListener('click', () => {
        if (i === this.currentNum) return;
        this.oldCurrentNum = this.currentNum;
        this.currentNum = i;

        mesh._targetChangeSlide(this.currentNum);
        _targetCircleAnimation();
        this.sliderAnimation.restart();
      });
    }
  }
}
